import type { WritableComputedRef } from 'vue';
import { computed, nextTick } from 'vue';
import type { I18n, I18nOptions } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { DEFAULT_LOCALE, LOCAL_STORAGE_KEY, SUPPORT_LOCALES } from '../constants';
import type { Locale } from '../types';

export const useI18nSetup = () => {
  const createI18nBase = (options?: I18nOptions) => {
    const i18n: I18n = createI18n({
      ...options,
      formatFallbackMessages: true,
      legacy: false,
      globalInjection: true,
    });
    setI18nLanguage(i18n, options?.locale as Locale);
    return i18n;
  };

  const setI18nLanguage = (i18n: I18n, locale?: Locale) => {
    const lang = locale || DEFAULT_LOCALE;
    if (i18n.mode === 'legacy') {
      i18n.global.locale = lang;
    } else {
      (i18n.global.locale as WritableComputedRef<string>).value = lang;
    }
  };

  const updateSetting = (locale: Locale) => {
    document.querySelector('html')?.setAttribute(LOCAL_STORAGE_KEY, locale);
    localStorage.setItem(LOCAL_STORAGE_KEY, locale);
  };

  const loadLocaleMessageComposeAPI = async (locale: Locale, setLocalCallback: any) => {
    try {
      const messages = await import(`./../locales/${locale}.json`);
      setLocalCallback(locale, messages.default);
    } catch (e) {
      console.warn(e);
      setLocalCallback(locale, {});
    }

    updateSetting(locale);
    return nextTick();
  };

  const loadLocaleMessage = async (i18n: I18n, locale: Locale, setLocalCallback?: any) => {
    const setFn = setLocalCallback ? setLocalCallback : i18n.global.setLocaleMessage;
    try {
      const messages = await import(`./../locales/${locale}.json`);
      setFn(locale, messages.default);
    } catch (e) {
      console.warn(e);
      setFn(locale, {});
    }

    updateSetting(locale);
    return nextTick();
  };

  const loadOnBeforeEach = async (i18n: I18n, locale: string, callback: any) => {
    if (!SUPPORT_LOCALES.includes(locale as string)) {
      return callback();
    }

    if (!i18n.global.availableLocales.includes(locale as string)) {
      await loadLocaleMessage(i18n, locale as string);
    }

    setI18nLanguage(i18n, locale as string);
  };

  const getInitLang = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamsLang = urlParams.get('lang');
    const localStorageLang = localStorage.getItem(LOCAL_STORAGE_KEY);

    return queryParamsLang ?? localStorageLang ?? 'en';
  };

  return {
    createI18nBase,
    loadLocaleMessage,
    setI18nLanguage,
    loadOnBeforeEach,
    loadLocaleMessageComposeAPI,
    updateSetting,
    getInitLang,
    lang: computed(() => getInitLang()),
  };
};

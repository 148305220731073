import { useI18nSetup } from '..';

export const getLabelWithLang = (label?: string | Record<string, string>) => {
  const { lang } = useI18nSetup();
  if (!label) return '';
  if (typeof label === 'string') {
    return label;
  }

  return label?.[lang.value] ?? label?.en ?? '';
};

export const getLabelWithLangDefaultKey = (
  label?: string | Record<string, string>,
  options?: {
    lang?: string;
    lowerCase?: boolean;
  },
): string => {
  console.log('label eeee', label);
  const { lang = 'en', lowerCase = true } = options || {};
  if (!label) return '';

  const getProcessedLabel = (value: string) => (lowerCase ? value.toLocaleLowerCase() : value);

  if (typeof label === 'string') {
    return getProcessedLabel(label);
  }

  const selectedLabel = label[lang] ?? label.en ?? '';
  return getProcessedLabel(selectedLabel);
};
